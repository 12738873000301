<template>
  <div class="addAddress absolute">
    <div class="list">
      <div class="item acea-row row-between-wrapper">
        <div style="color: #2291f8">上课人信息</div>
      </div>
      <div
        class="item acea-row row-between-wrapper"
        v-show="type == 1 && userAddress.old_uid == initUid"
      >
        <div class="realname">学号UID</div>
        <input
          type="text"
          placeholder="请输入学号"
          v-model="userAddress.uid"
          required
        />
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="realname">姓名</div>
        <input
          type="text"
          placeholder="请输入姓名"
          v-model="userAddress.realname"
          required
        />
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="name">手机号码</div>
        <input
          type="text"
          placeholder="请填写手机号码"
          v-model="userAddress.phone"
          required
        />
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="name">听课地区</div>
        <input
          type="text"
          placeholder="请填写听课地区，如“合肥”"
          v-model="userAddress.site_city"
        />
      </div>
    </div>
    <!-- v-if="userAddress.is_store !==0" -->
    <div
      class="list"
      style="margin-top: 10px"
      v-if="userAddress.is_store !== 0"
    >
      <div class="item acea-row row-between-wrapper" style="color: #888">
        <div class="name" style="color: #2291f8">收货人信息</div>
        <div>
          <mt-switch v-model="infoSwitch">信息同上</mt-switch>
        </div>
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="name">姓名</div>
        <input
          type="text"
          placeholder="请输入姓名"
          v-model="userAddress.store_realname"
          required
        />
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="name">联系电话</div>
        <input
          type="text"
          placeholder="请输入联系电话"
          v-model="userAddress.store_phone"
          required
        />
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="name">所在地区</div>
        <div
          class="picker acea-row row-between-wrapper select-value form-control"
        >
          <div class="address">
            <div slot="right" @click.stop="show2 = true">
              {{ userAddress.store_district || "请选择收货地址" }}
            </div>
            <CitySelect
              ref="cityselect"
              v-model="show2"
              :callback="result2"
              :items="district"
              :ready="ready"
              provance=""
              city=""
              area=""
            ></CitySelect>
          </div>
          <div class="jym-icon-test20"></div>
        </div>
      </div>
      <div class="item acea-row row-between-wrapper">
        <div class="name">详细地址</div>
        <input
          type="text"
          placeholder="请填写具体地址"
          v-model.trim="userAddress.address"
          required
        />
      </div>
      <div class="item acea-row row-between-wrapper" v-if="options.length">
        <div class="name">选择套盒</div>
        <cw-input-select
          v-if="options.length"
          ref="cwInputLesson"
          @getSearchValue="getSearchValue"
          :options="options"
          label-name="label"
        ></cw-input-select>
      </div>
    </div>

    <div class="keepBnt" @click="submit">立即保存</div>
  </div>
</template>
<script type="text/babel">
import { Toast } from "mint-ui";
import { CitySelect } from "vue-ydui/dist/lib.rem/cityselect";
import { studentGetAddress, studentAddressSave } from "@api/user";
import cwInputSelect from "@components/cw-input-select";
const CITYLIST = require("@assets/js/city_list");

export default {
  components: {
    CitySelect,
    cwInputSelect,
  },
  data() {
    return {
      show2: false,
      district: [],
      options: [],
      id: 0,
      type: "",
      initUid: "",
      userAddress: {
        uid: "",
        realname: "",
        phone: "",
        site_city: "",
        store_realname: "",
        store_phone: "",
        store_district: "",
        address: "",
        remark: "",
        is_store: "",
      },
      address: {},
      ready: false,
      infoSwitch: false,
    };
  },
  watch: {
    infoSwitch(val) {
      if (val) {
        this.userAddress.store_realname = this.userAddress.realname;
        this.userAddress.store_phone = this.userAddress.phone;
      }
    },
  },
  created: function () {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.getUserAddress();
    this.getCityList();
    console.log(this.type);
  },
  methods: {
    // 搜索条件赋值
    getSearchValue(val) {
      let that = this;
      that.userAddress.remark = val.value;
    },

    getCityList: function () {
      let that = this;
      that.district = CITYLIST;
      that.ready = true;
    },
    getUserAddress() {
      let that = this;
      studentGetAddress(that.type, that.id)
        .then((res) => {
          that.userAddress = res.data;
          that.initUid = res.data.uid;
          that.address.province = res.data.province;
          that.address.city = res.data.city;
          that.address.city_id = res.data.city_id;
          that.address.district = res.data.district;

          that.options = res.data.gift_names.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        })
        .catch((err) => {
          that.$dialog.error(err.msg);
        });
    },
    submit() {
      // debugger
      let that = this;
      let userAddress = that.userAddress;

      if (userAddress.is_store == 0) {
        if (!/^[\u4E00-\u9FA5]{2,10}$/.test(userAddress.realname)) {
          Toast({ message: "姓名必填且为2-10位的中文字符" });
          return false;
        } else if (!/^1[3456789]\d{9}$/.test(userAddress.phone)) {
          Toast({ message: "上课人联系电话不正确" });
          return false;
        } else {
          const params = {
            ...userAddress,
          };
          studentAddressSave(that.id, that.type, params)
            .then((res) => {
              let url = that.type == 1 ? "/user/networkStudentList" : "/user";
              that.$dialog.success(res.msg);
              that.$router.push(url);
            })
            .catch((err) => {
              that.$dialog.error(err.msg);
            });
        }
      } else {
        if (!/^[\u4E00-\u9FA5]{2,10}$/.test(userAddress.realname)) {
          Toast({ message: "姓名必填且为2-10位的中文字符" });
          return false;
        } else if (!/^1[3456789]\d{9}$/.test(userAddress.phone)) {
          Toast({ message: "上课人联系电话不正确" });
          return false;
        } else if (!userAddress.store_realname) {
          Toast({ message: "收货人姓名不能为空" });
          return false;
        } else if (!/^1[3456789]\d{9}$/.test(userAddress.store_phone)) {
          Toast({ message: "收货人联系电话不正确" });
          return false;
        } else if (!userAddress.store_district) {
          Toast({ message: "所在地区不能为空" });
          return false;
        } else if (!userAddress.address) {
          Toast({ message: "详细地址不能为空" });
          return false;
        } else {
          const params = {
            ...userAddress,
          };
          studentAddressSave(that.id, that.type, params)
            .then((res) => {
              let url = that.type == 1 ? "/user/networkStudentList" : "/user";
              that.$dialog.success(res.msg);
              that.$router.push(url);
            })
            .catch((err) => {
              that.$dialog.error(err.msg);
            });
        }
      }

      // if (!/^[\u4E00-\u9FA5]{2,10}$/.test(userAddress.realname)) {
      //   Toast({ message: "姓名必填且为2-10位的中文字符" });
      //   return false;
      // } else if (!/^1[3456789]\d{9}$/.test(userAddress.phone)) {
      //   Toast({ message: "上课人联系电话不正确" });
      //   return false;
      // } else if (!userAddress.store_realname) {
      //   Toast({ message: "收货人姓名不能为空" });
      //   return false;
      // } else if (!/^1[3456789]\d{9}$/.test(userAddress.store_phone)) {
      //   Toast({ message: "收货人联系电话不正确" });
      //   return false;
      // } else if (!userAddress.store_district) {
      //   Toast({ message: "所在地区不能为空" });
      //   return false;
      // } else if (!userAddress.address) {
      //   Toast({ message: "详细地址不能为空" });
      //   return false;
      // }
      // else {
      //   const params = {
      //     ...userAddress,
      //   };
      //   studentAddressSave(that.id, that.type, params)
      //     .then((res) => {
      //       let url = that.type == 1 ? "/user/networkStudentList" : "/user";
      //       that.$dialog.success(res.msg);
      //       that.$router.push(url);
      //     })
      //     .catch((err) => {
      //       that.$dialog.error(err.msg);
      //     });
      // }
    },
    result2(ret) {
      this.userAddress.store_district =
        ret.itemName1 + " " + ret.itemName2 + " " + ret.itemName3;
      this.address.province = ret.itemName1;
      this.address.city = ret.itemName2;
      this.address.city_id = ret.itemValue2;
      this.address.district = ret.itemName3;
    },
  },
};
</script>
